.maintenance {
  margin: 100px auto;
  display: flex;

  .logo {
    background: url("../../../images/logo-drrakon.webp") center no-repeat;
    background-size: contain;
    width: 400px;
    margin: 0 auto;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 100%;
    }

    @include mobile {
      width: 100%;
    }
  }
  h2 {
    margin: 44px auto 0;
    font-family: $font-family-finist;
    font-weight: 400;
    font-size: 60px;
    line-height: 2;
    letter-spacing: 3px;
    color: #fff;
    text-transform: uppercase;
    @include mobile {
      font-size: 40px;
    }
  }
}
