.bg-snake-skin-1920 {
  width: 100vw;
  margin: 0 auto;
  max-width: 1920px;
  background: url("../../../images/bg-snake-skin.webp") center no-repeat;
  background-size: cover;
}

.about {
  margin: 0 auto;
  padding: 50px 0 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1168px;
  position: relative;
  z-index: 6;
  @include tablet {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 0 300px;
  }
  @include mobile {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @include mobile-small {
    padding-top: 50px;
    padding-bottom: 100px;
  }

    .video-container {
      position: relative;
      width: 568px;
      height: 609px;
      @include tablet {
        margin: 200px 0 0;
        width: 75vw;
        height: 80vw;
      }
      @include mobile {
        width: 80vw;
        height: 85vw;
        margin-top: 100px;
      }
      @include mobile-small {
        margin-top: 50px;
      }


        .video-backborder {
          width: 100%;
          height: 100%;
          border: 20px solid #151515;
          position: absolute;
          top: 0;
          left: 0;
        }
        .video-blood {
          width: 444px;
          height: 440px;
          background: url("../../../images/blood-slop.webp") center no-repeat;
          background-size: contain;
          position: absolute;
          top: -120px;
          left: -140px;
          @include mobile {
            width: 300px;
            height: 300px;
            top: -90px;
            left: -80px;
          }
          @include mobile-small {
            width: 240px;
            height: 240px;
            top: -60px;
            left: -60px;
          }
        }
        .video-about {
          width: 568px;
          height: 609px;
          background-color: #0B0B0B;
          position: relative;
          top: 40px;
          left: 40px;
          &:before {
            width: 100%;
            height: 100%;
            display: block;
            content: "";
            background-color: rgba(0,0,0,.9);
            position: absolute;
            top: 0;
            left: 0;
          }
          @include tablet {
            width: 100%;
            height: 100%;
          }
        }
        .play-btn {
          background-color: #151515;
          padding: 24px 40px;
          position: absolute;
          bottom: 0;
          left: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include tablet {
            left: auto;
            right: 40px;
            width: 50vw;
            padding: 5.2vw 0;
            font-size: 3vw !important;
          }
          @include mobile-small {
            right: 20px;
          }

          &:before {
            display: block;
            content: '';
            width: 15px;
            height: 15px;
            background: url("../../../images/icon-play.svg") center no-repeat;
            background-size: contain;
            margin-right: 4px;
            @include tablet {
              width: 30px;
              height: 30px;
            }
            @include mobile-small {
              width: 24px;
              height: 24px;
            }
          }
        }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 600px;
      @include tablet {
        min-width: unset;
        width: 100%;
      }

        .section-header {
          position: relative;
          z-index: 15;
        }

        .info-text {
          margin-top: 20px;
          margin-left: 100px;
          text-align: left;
          font-size: 17px;
          line-height: 1.58;
          opacity: .5;
          font-family: $font-family-gotham;
          p, a, b, strong, span {
            font-family: $font-family-morpheus;
            width: 100%;
            word-break: normal;
            color: #e5e6ed !important;
          }
          blockquote {
            font-family: $font-family-morpheus;
            font-style: italic;
          }

          @include tablet {
            font-size: 36px;
            line-height: 1;
            margin-top: 40px;
            margin-left: 0;
            width: 100%;
          }
          @include mobile {
            font-size: 30px;
            margin-top: 16px;
          }
          @include mobile-small {
            font-size: 24px;
          }
        }

        .info-btn {
          padding: 25px 0;
          margin-left: 100px;
          margin-top: 30px;
          @include tablet {
            margin: 50px 0 0;
            padding: 5.2vw 0;
            width: 50vw;
            font-size: 3vw !important;
          }
          @include mobile {
            margin-top: 32px;
          }
          @include mobile-small {
            margin-top: 16px;
          }
        }
    }
}
