.no-content {
  margin: 46px auto;
  max-width: calc(100vw - 40px);
    p {
      margin: 0;
      padding: 0;
      font-family: $font-family-montserrat;
      font-size: 16px;
    }
}
