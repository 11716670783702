.page-news {
  position: relative;
  z-index: 10;
  .section-header {
    width: 100%;
  }
  .news-container {
    width: 100%;
    margin: 67px auto 0;
    display: flex;
    flex-direction: column;
    @include tablet {
      margin-top: 100px;
    }
    @include mobile {
      margin-top: 32px;
    }
      .article {
        width: 100%;
        margin-bottom: 154px;
        @include tablet {
          margin-bottom: 100px;
        }
        @include mobile {
          margin-bottom: 32px;
        }
          .images-block {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            height: 368px;
            background-color: #151515;
            @include tablet {
              height: 279px;
            }
            @include mobile {
              height: 185px;
            }
              .image-wrapper {
                width: 100%;
                height: 100%;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
              }
          }

          .top-block {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 50px auto 0;
            @include tablet {
              margin-top: 20px;
            }
            @include mobile {
              margin-top: 15px;
            }

              .title {
                font-family: $font-family-finist;
                letter-spacing: 1px;
                font-style: normal;
                font-weight: normal;
                font-size: 60px;
                line-height: 1.2;
                display: flex;
                align-items: center;
                text-align: left;
                color: #fff;
                @include mobile {
                  font-size: 44px;
                }
                @include mobile-small {
                  font-size: 32px;
                }
              }
              .date {
                min-width: 115px;
                white-space: nowrap;
                margin-left: 40px;
                font-family: $font-family-finist;
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 1.2;
                text-align: right;
                display: flex;
                align-items: center;
                color: $red;
                text-transform: uppercase;
                margin-bottom: 0;
                position: relative;
                top: 20px;
                @include tablet {
                  white-space: break-spaces;
                }
                @include mobile {
                  font-size: 26px;
                  top: 10px;
                }
              }
          }
      }

      .text {
        width: 100%;
        margin-top: 50px;
        font-family: $font-family-montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.2;
        text-align: left;
        color: #B6B6B6;
        @include tablet {
          margin-top: 75px;
        }
        @include mobile {
          margin-top: 15px;
          font-size: 12px;
        }
          p, a, b, strong, span {
            font-family: $font-family-morpheus;
            color: #b6b6b6 !important;
            font-size: 26px;
            @include mobile {
              font-size: 20px;
            }
          }
      }
  }

}
