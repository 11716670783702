.main-wrapper {
  width: 100vw;
  overflow-x: hidden;
}
.section {
  max-width: 1342px;
  width: calc(100% - 64px);

  margin: 0 auto;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  @include tablet {
    width: calc(100% - 160px);
  }
  @include mobile {
    width: calc(100% - 64px);
  }
}

.page {
  margin: 123px auto 200px;
  min-height: 60vh;
  max-width: 1168px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .section-header {
    width: 100%;
  }
  @include desktop {
    width: calc(100% - 160px);
  }
  @include tablet {
    margin: 100px auto 150px;
    width: calc(100% - 160px);
  }
  @include mobile {
    margin: 50px auto 100px;
    width: calc(100% - 64px);
  }
}

.section-header {
  width: fit-content;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 10;

    .small-header {
      font-family: $font-family-finist;
      color: #fff;
      font-size: 50px;
      letter-spacing: 5px;
      line-height: 0.9;
      @include tablet {
        font-size: 54px;
      }
      @include mobile {
        font-size: 7vw;
      }
    }
    .big-header {
      font-family: $font-family-finist;
      letter-spacing: 5px;
      color: $red;
      font-size: 122px;
      line-height: 0.9;
      @include tablet {
        font-size: 130px;
      }
      @include mobile {
        font-size: 16vw;
      }
    }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

// animations
.video-container {
  cursor: pointer;
  .video-title {
    transition: color linear .2s;
  }
  &:hover {

    .video-play-block {
        animation: shakeX; /* referring directly to the animation's @keyframe declaration */
        animation-duration: .5s; /* don't forget to set a duration! */
    }

    .video-title {
      animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 1s; /* don't forget to set a duration! */
      color: $red;
      transition: color linear 1s;
    }
  }
}

.social-links-link,
.social-link {
  transition: all ease-in-out .2s;
  &:hover {
    transition: all ease-in-out .2s;
    background-size: 70% 70% !important;
  }
}
