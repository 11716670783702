.spotify {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  background-size: cover;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  @include tablet {
    background: none;
    padding: 0;
    width: calc(100% - 160px);
  }
  @include mobile {
    width: calc(100% - 64px);
  }
    .photo-square {
      width: 20%;
      position: relative;
      @media screen and (max-width: 1320px) {
        width: 25%;
      }
      @include tablet {
        width: 100%;
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

    }
  .wrapper-1168mw {
    position: absolute;
    top: calc(50% - 162px);
    left: 19%;
    z-index: 10;
    width: 100%;
    max-width: 1168px;
    display: flex;
    justify-content: flex-start;
    @include desktop {
      width: calc(100% - 160px);
    }
    @include tablet {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

    .spotify-info-block {
      width: 670px;
      margin: 0;
      padding: 64px 0 66px 70px;
      background-color: rgba(0, 0, 0, .9);
      max-width: 100%;
      @include tablet {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #000;
      }

        .section-header {
          margin: 0;
          padding: 0;
            .small-header {
              font-size: 44px;
              line-height: 0.9;
            }
            .big-header {
              font-size: 56px;
              line-height: 0.9;
            }
          @include tablet {
            margin-top: 20vw;
            .small-header,
            .big-header {
              font-size: 10vw;
            }
          }
        }

        .spotify-btns-block {
          margin: 32px 0 0;
          padding: 0;
          display: flex;
          align-items: center;
          @include tablet {
            margin-top: 91px;
            margin-bottom: 20vw;
          }
          @include mobile {
            margin-top: 50px;
          }
          @include mobile-small {
            margin-top: 32px;
          }

          .spotify-btn {
              padding: 24px 0;
              font-size: 16px !important;
              margin-right: 28px;
              @include tablet {
                padding: 5.2vw 0;
                width: 50vw;
                font-size: 3vw !important;
              }
            }
            .spotify-gallery-link {
              text-decoration: none;
              text-transform: uppercase;
              color: #fff;
              opacity: .3;
              font-size: 16px;
              line-height: 1.15;
              font-family: $font-family-morpheus;
              display: flex;
              align-items: center;
              svg {
                margin-right: 6px;
              }
              &:hover {
                color: #fff !important;
                opacity: 1;
              }

              @include tablet {
                font-size: 3vw !important;
              }
            }
        }
    }
}
