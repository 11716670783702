.section.video {
  margin: 0 auto;
  padding: 146px 0 20px;
  flex-direction: column;
  @include tablet {
    padding: 230px 0 0;
  }
  @include mobile {
    padding-top: 100px;
  }
  @include mobile-small {
    padding-top: 50px;
  }

    .section-header {
      width: fit-content;
      margin: 0 auto;

        .small-header {
          margin: 0 auto;
          text-align: center;
        }
    }

    .videos-container {
      margin: 112px auto 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      @include tablet {
        margin-top: 160px;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      @include mobile {
        margin-top: 100px;
      }
      @include mobile-small {
        margin-top: 50px;
      }

        .video-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 100px;
          position: relative;
          z-index: 50;
          @include desktop {
            margin-right: 60px;
          }
          &:last-of-type {
            margin-right: 0;
          }
          @include tablet {
            width: 50%;
            margin: 0 0 6vw;
          }

            .video-play-block {
              width: 96px;
              height: 96px;
              border: 20px solid #151515;
              background: url("../../../images/icon-play.svg") center no-repeat;
              background-size: 50% 50%;
              @include tablet {
                width: 50%;
                height: initial;
                margin:  0 auto;
                position: relative;
                &:before {
                  display: block;
                  content: '';
                  width: 100%;
                  padding-top: 100%;
                  top: 0;
                  left: 0;
                }
              }
            }

            .video-title {
              margin: 44px 0 0;
              font-family: $font-family-morpheus;
              width: 140px;
              text-align: center;
              font-size: 24px;
              line-height: 1.05;
              @include tablet {
                width: 100%;
                margin-top: 70px;
                font-size: 56px;
              }
              @include mobile {
                font-size: 44px;
                margin-top: 50px;
              }
              @include mobile-small {
                font-size: 32px;
                margin-top: 32px;
              }
            }
          &:nth-of-type(2n - 1) {
            .video-title {
              margin-top: 76px;
              @include tablet {
                margin-top: 70px;
              }
            }
          }
          &:nth-of-type(2n) {
            padding-top: 12vw;
          }

        }
    }
}
