.page-photo {
  //width: 100%;
  position: relative;
  z-index: 10;
  .section-header {
    width: 100%;
  }


  .album-container {
    width: 100%;
    margin: 75px auto 0;
    @include tablet {
      margin-top: 46px;
    }
    @include mobile {
      margin-top: 30px;
    }

    .photo-container {
      background-color: #5c636a;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2%;

    .photo-container {
      cursor: pointer;
      width: 49%;
      position: relative;
      margin-bottom: 30px;
      height: 432px;
      @include mobile {
        width: 100%;
        height: 322px;
      }
      @include mobile-small {
        height: 257px;
      }

      &:nth-of-type(3n - 2) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }
}
