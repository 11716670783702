@import "./assets/scss/fonts.css";
@import "./assets/scss/all.scss";

.App {
  min-height: 100vh;
  text-align: center;
  position: relative;
  background-color: #000;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: $red;
  outline: 1px solid $red;
}


.background-dragon-top {
  background: url("./assets/images/bg-top-wide.webp") center no-repeat;
  background-size: contain;
  width: 1920px;
  height: 940px;
  position: absolute;
  top: 0;
  left: calc(50% - 960px);
  z-index: 5;
  @include tablet {
    background: url("./assets/images/tablet-snake-skin-top.webp") center no-repeat;
    background-size: contain;
    width: 1080px;
    height: 250vw;
    top: 0;
    left: calc(50% - 540px);
  }
}

.bg-top-for-pages {
  background: url("./assets/images/bg-top-for-pages.webp") center no-repeat;
  background-size: contain;
  width: 1920px;
  height: 940px;
  position: absolute;
  top: 0;
  left: calc(50% - 960px);
  z-index: 0;
}

.background-bottom {
  background: url("./assets/images/bg-bottom-skull+flames.webp") center no-repeat;
  background-size: contain;
  width: 1920px;
  height: 1621px;
  position: absolute;
  bottom: 115px;
  left: calc(50% - 960px);
  z-index: 9;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 6;
  }
  @include desktop {
    background: url("./assets/images/bg-flame.webp") center no-repeat;
    background-size: contain;
    width: 2729px;
    height: 1500px;
    left: calc(50% - 1364px);
    z-index: 5;
  }
}
.bg-hole-desktop {
  display: none;
  @include desktop {
    display: block;
    width: 1920px;
    height: 997px;
    background: url("./assets/images/hole.webp") center no-repeat;
    background-size: 1920px 1010px;
    position: absolute;
    z-index: 4;
    left: calc(50% - 1070px);
    bottom: 600px;
  }
  @include tablet {
    display: none;
  }
}

.bg-smoke-snake-skin-1920 {
  margin: 0 auto;
  width: 100vw;
  max-width: 1920px;
  background: url("./assets/images/bg-snake-skin-smoke.webp") center no-repeat;
  background-size: cover;
  position: relative;
}
