.page-about {
  position: relative;
  z-index: 10;
  .about-text {
    width: 100%;
    margin: 67px auto 0;
    padding-left: 130px;
    display: flex;
    flex-direction: column;
    color: #575757 !important;
    @include tablet {
      margin-top: 100px;
      padding-left: 0;
    }
    @include mobile {
      margin-top: 32px;
    }

      blockquote {
        font-family: $font-family-morpheus;
        font-size: 26px;
        color: #575757 !important;
        font-style: italic;
      }
      p, a, b, span, strong {
        font-family: $font-family-morpheus;
        text-align: left;
        color: #575757 !important;
        font-size: 26px;
        @include tablet {
          font-size: 38px;
        }
        @include mobile {
          font-size: 24px;
        }
      }

  }
}
