.footer {
  margin: 0 auto;
  width: 100vw;
  padding: 86px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
  @include tablet {
    padding: 0;
  }

  &.pages {
    background: url("../../images/bg-footer-for-pages.webp") bottom no-repeat;
    background-size: cover;
    @include tablet {
      background: none;
      .smoke-for-pages-desktop {
        width: 100vw;
        height: 140vw;
        position: absolute;
        top: -100px;
        left: 0;
        background: url("../../images/bg-bottom-tablet.webp") center no-repeat;
        background-size: contain;
        z-index: -2;
      }
    }

    .footer-contacts {
      .skull-bg {
        position: absolute;
        z-index: -1;
        background: url("../../images/skull-desktop.webp") center no-repeat;
        background-size: contain;
        display: block;
        width: 679px;
        height: 450px;
        bottom: -200px;
        left: calc(50% - 339px);

        @include desktop {
          background: url("../../images/skull-desktop.webp") center no-repeat;
          background-size: contain;
          display: block;
          width: 679px;
          height: 450px;
          bottom: -1000px;
          left: calc(50% - 339px);
        }
        @include tablet {
          background: url("../../images/skull-bottom.webp") center no-repeat;
          background-size: contain;
          width: 100vw;
          height: 90vw;
          top: 0;
          left: 0;
        }
      }
    }
    .footer-social-links {
      @include desktop {
        padding-bottom: 25px;
      }
      @include tablet {
        padding-bottom: 125px;
      }
    }
    }

    .footer-contacts {
      .skull-bg {
        display: none;
        position: absolute;
        z-index: -1;
        @include desktop {
          background: url("../../images/skull-bottom.webp") center no-repeat;
          background-size: contain;
          display: block;
          width: 679px;
          height: 450px;
          top: -100px;
          left: calc(50% - 339px);
        }
        @include tablet {
          background: url("../../images/skull-bottom.webp") center no-repeat;
          background-size: contain;
          width: 100vw;
          height: 90vw;
          top: 0;
          left: 0;
        }
      }

      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      @include tablet {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100vw;
      }

        .contacts-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          width: fit-content;
          margin: 24px auto 0;
          &.phone {
            display: none;
          }
          @include tablet {
            order: 2;
            align-items: center;
            margin-bottom: 5vw;
            width: calc(100% - 50px);
            &.email {
              margin-top: 6vw;
            }
          }

          .email {
            width: fit-content;
            align-items: center;
          }


          .contacts-text {
              font-family: $font-family-morpheus;
              font-size: 17px;
            font-weight: 700;
              line-height: 1.19;
              text-transform: uppercase;
            word-break: break-word;
            @include tablet {
              font-size: 42px;
              margin: 0;
            }
            @include mobile-small {
              font-size: 17px;
            }
              &.contacts-title {
                color: $red;
              }
            }
        }
        .contacts-logo {
          margin: 0 56px;
          display: block;
          width: 182px;
          height: 142px;
          background: url("../../images/logo.svg") center no-repeat;
          background-size: contain;
          @include tablet {
            order: 1;
            width: 50vw;
            height: 30vw;
            margin-bottom: 9vw;
          }
          @media screen and (max-width: 735px) {
            margin-top: 50px;
          }
          @include mobile-small {
            margin-top: 0;
            width: 60vw;
            height: 40vw;
          }
        }
      &.with-phone {
        flex-direction: row;
        align-items: flex-end;
        @include tablet {
          flex-direction: column;
          align-items: center;
        }
        .contacts-item {
          align-items: flex-end;
          bottom: 13px;
          width: calc((100% - 50px) / 3);
          margin: 0;
          @include tablet {
            width: calc(100% - 50px);
            margin: 0 auto 24px;
            align-items: center;
          }
          &.phone {
            display: flex;
          }
          &.email {
            align-items: flex-start;
            @include tablet {
              align-items: center;
              margin-bottom: 0;
            }
          }

        }
      }

    }

    .footer-social-links {
      margin: 40px auto 0;
      padding: 0 0 80px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      @include tablet {
        margin: 150px auto 0;
        padding-bottom: 175px;
      }
      @include mobile {
        margin-top: 50px;
        padding-bottom: 125px;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 670px) {
        width: calc(100% - 64px);
      }
      @include mobile-small {
        margin-top: 50px;
        padding-bottom: 50px;
      }

        .social-links-link {
          width: 50px;
          height: 50px;
          background-color: #151515;
          margin-right: 12px;
          &.spotify-icon {
            background: #151515 url("../../images/icon-spotify.svg") center no-repeat;
            background-size: 50% 50%;
          }
          &.yandex-icon {
            background: #151515 url("../../images/icon-yandex-music.svg") center no-repeat;
            background-size: 50% 50%;
          }
          &.bandcamp-icon {
            background: #151515 url("../../images/icon-bandcamp.svg") center no-repeat;
            background-size: 50% 50%;
          }
          &.apple-icon {
            background: #151515 url("../../images/icon-apple.svg") center no-repeat;
            background-size: 50% 50%;
          }
          &.youtube-icon {
            background: #151515 url("../../images/icon-yt.svg") center no-repeat;
            background-size: 50% 50%;
          }
          &.vk-icon {
            background: #151515 url("../../images/icon-vk.svg") center no-repeat;
            background-size: 50% 50%;
          }
          @include tablet {
            width: 138px;
            height: 138px;
            margin-right: 32px;
          }
          @include mobile {
            margin-bottom: 32px;
            width: calc((100% - 96px) / 4);
            &:nth-of-type(4n) {
              margin-right: 0;
            }
          }
          @media screen and (max-width: 670px) {
            margin-right: 6px;
            margin-bottom: 12px;
            width: calc((100% - 18px) / 4);
          }
          @include mobile-small {
            width: calc((100% - 12px) / 3);
            height: initial;
            position: relative;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-of-type(4n) {
              margin-right: 6px;
            }
            &:after {
              display: block;
              content: '';
              padding-bottom: 100%;
            }
          }
          &:last-of-type {
            margin-right: 0;
            }
        }
    }

    .footer-bottom-part {
      margin: 0 auto;
      padding: 45px 0;
      background-color: #151515;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .flex-wrapper {
        display: flex;
        @include mobile {
          flex-direction: column;
          align-items: center;
        }
      }
      @include tablet {
        flex-direction: column;
        padding: 110px 0 125px;
      }
      @include mobile {
        padding: 90px 0 100px;
      }
      @include mobile-small {
        padding: 50px 0 60px;
      }

        p {
          font-family: $font-family-morpheus;
          font-size: 14px;
          line-height: 1.8;
          margin: 0;
          padding: 0;
          color: #54525B;
          @include tablet {
            font-size: 36px;
            line-height: 1.2;
          }
          @include mobile {
            font-size: 24px;
          }
          @include mobile-small {
            font-size: 18px;
          }
        }

        .band-name {
          color: #fff;
        }

        .footer-docs {
          @include tablet {
            margin-top: 60px;
          }
          @include mobile {
            display: flex;
            flex-direction: column;
          }
        }
        .docs-link {
          text-decoration: none;
          color: #fff;
        }
    }
}
