.subscribe.bg-wrapper {
  width: 100vw;
  padding: 0;
  position: relative;
  z-index: 10;
  background: transparent;
  @include desktop {
    background-color: #151515;
  }
  &:before {
    content: '';
    display: block;
    width: 1920px;
    height: 1240px;
    position: absolute;
    top: 0;
    background: url("../../../images/tunnel+torches-ws.webp") center no-repeat;
    left: calc(50% - 960px);
    z-index: -1;
    @include desktop {
      display: none;
    }
  }
}

.section.subscribe {
  width: 100%;
  max-width: 1168px;
  margin: 0 auto;
  padding: 84px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include desktop {
    width: calc(100% - 160px);
  }
  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    padding: 135px 0;
  }
  @include mobile {
    width: calc(100% - 64px);
  }

    .subscribe-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

        .title {
          font-size: 49px;
          font-family: $font-family-finist;
          color: #fff;
          line-height: 0.9;
          @include tablet {
            font-size: 11vw;
          }
        }
        .subtitle {
          text-align: left;
          font-size: 17px;
          line-height: 1.5;
          color: #a1a1a1;
          font-family: $font-family-morpheus;
          @include tablet {
            font-size: 36px;
          }
          @include mobile {
            font-size: 24px;
          }
          @include mobile-small {
            font-size: 18px;
          }
        }
    }

    .subscribe-form {
      display: flex;
      align-items: center;
      height: 75px;
      @include tablet {
        height: initial;
        margin-top: 150px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      @include mobile {
        margin-top: 100px;
      }
      @include mobile-small {
        margin-top: 32px;
      }

        .subscribe-input {
          height: 100%;
          border: none;
          border-radius: 0;
          width: 390px;
          background-color: #0d0d0d;

          font-family: $font-family-morpheus;
          font-size: 17px;
          text-transform: uppercase;
          @include desktop {
            width: 300px;
          }
          @include tablet {
            width: 80%;
            font-size: 3vw;
            padding: 5.2vw 0 5.2vw 70px;
          }
          @include mobile-small {
            width: 100%;
          }

          &::placeholder {
            font-size: 17px;
            text-transform: uppercase;
            font-family: $font-family-morpheus;
            color: #fff;
            opacity: .3;
            @include tablet {
              font-size: 3vw;
            }
          }
          &:focus {
            outline: none;
          }
        }
        .subscribe-btn {
          width: 256px;
          height: 100%;
          padding: 0;
          @include desktop {
            width: 200px;
          }
          @include tablet {
            margin-top: 40px;
            width: 50vw;
            padding: 5.2vw 0;
            font-size: 3vw !important;
          }
        }
    }
}
