.article-modal {
  .modal-content {
    background-color: #151515;
    .news-modal-article {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .image-container {
        width: 100%;
        height: 400px;
        background-color: #000;
        @include  tablet {
          height: 30vw;
        }
        @include mobile {
          max-height: 50vw;
          order: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .article-title {
        text-align: left;
        margin-top: 32px;
        width: 50%;
        flex-grow: 1;
        padding-left: 32px;
        font-family: $font-family-finist;
        font-weight: 500;
        letter-spacing: 3px;
        font-size: 32px;
        line-height: 1;
        color: #fff;
        text-transform: uppercase;
        @include tablet {
          font-size: 24px;
        }
        @include mobile {
          width: 100%;
          order: 3;
        }
        @include mobile-small {
          margin-top: 25px;
          font-size: 32px;
        }
      }

      .article-date {
        width: fit-content;
        font-size: 22px;
        padding-right: 32px;
        line-height: 1.15;
        margin: 16px auto 0;
        text-align: right;
        font-family: $font-family-finist;
        letter-spacing: 2px;
        color: $red;
        text-transform: uppercase;
        position: relative;
        top: 10px;
        @include tablet {
          font-size: 24px;
          margin: 12px 0 0;
        }
        @include mobile {
          width: 100%;
          order: 2;
        }
        @include mobile-small {
          margin-top: 25px;
          font-size: 26px;
        }
      }

      .article-text {
        margin: 22px auto 0;
        width: 100%;
        padding: 0 32px;
        text-align: left;
        color: #fff;
        opacity: .5;
        font-family: $font-family-morpheus;
        font-size: 17px;
        line-height: 1.5;
        @include tablet {
          font-size: 32px;
          margin: 32px 0 0;
        }
        @include mobile {
          order: 4
        }
        @include mobile-small {
          margin-top: 25px;
          font-size: 22px;
        }
        p, a, b, strong, span {
          font-family: $font-family-morpheus;
          color: #b6b6b6 !important;
          font-size: 26px;
          @include mobile {
            font-size: 20px;
          }
        }

      }
    }

  }
}

.photo-modal {
  height: 95vh !important;
  .modal-content {
    width: initial !important;
    max-width: 100vw;
    height: 100%;
    margin: 0 auto;
  }
  .modal-body {
    width: initial;
    padding: 0;
    height: 100%;
  }
  .modal-image-container {
    cursor: pointer;
    max-width: calc(100vw - 400px);
    max-height: 95vh;
    img {
      width: 100%;
      height: 100%;
      max-height: 90vh;
      object-fit: scale-down !important;
      object-position: center;
    }

    @include mobile-small {
      max-width: calc(100vw - 40px);
    }
  }
}

.video-modal {
  .modal-content {
    height: 50vw !important;
    background-color: transparent !important;
  }
}
