.not-found {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

    .logo {
      background: url("../../../images/logo.svg") center no-repeat;
      width: 300px;
      height: 220px;
      position: relative;
      z-index: 10;
    }
    .title {
      margin: 100px auto;
      font-family: $font-family-goth;
      font-size: 72px;
      color: #fff;
      position: relative;
      z-index: 10;
      @include mobile {
        font-size: 50px;
      }
    }
}
