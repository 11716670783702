.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: calc(100vw - 354px);
  max-width: 1168px;
  margin: 0 auto;
  padding: 20px 0 0;
  position: relative;

  @media screen and (max-width: 1440px) {
    width: calc(100vw - 64px);
  }
  @include tablet {
    justify-content: flex-end;
    width: calc(100vw - 80px);
  }
  @media screen and (max-width: 710px) {
    justify-content: space-between;
  }
  @include mobile {
    width: calc(100vw - 64px);
  }

  .logo {
    min-width: 122px;
    width: 122px;
    height: 99px;
    background: url("../../images/logo.svg") center no-repeat;
    background-size: contain;
    margin-right: 66px;
    position: relative;
    z-index: 60;
    transition: all linear .2s;
    @include desktop {
      margin-right: 40px;
    }
    @include tablet {
      margin-right: 12px;
      width: 15vw;
      height: 10vw;
    }
    @include mobile {
      width: 142px;
      height: 110px;
      flex-grow: 1;
      background-position: left;
    }
  }

  .lang {
    margin: 0 12px;
    cursor: pointer;
    border-radius: 6px;
    z-index: 10;
    width: 54px;
    height: 42px;
    position: relative;
    top: 8px;
    flex-shrink: 0;
    @include tablet {
      height: 60px;
      width: 80px;
      top: 12px;
      margin-left: 0;
    }
    @include mobile-small {
      width: 54px;
      height: 42px;
      top: 8px;
    }
    &.ru {
        background: url("../../images/ru.svg") bottom no-repeat;
        background-size: cover;
    }
    &.en {
        background: url("../../images/en.svg") bottom no-repeat;
        background-size: cover;
    }

  }

  .nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    transition: all linear 0.3s;
    position: relative;
    z-index: 10;

    @include tablet {
      position: absolute;
      right: -100vw;
      transition: right ease-in-out .2s;
    }
    @include mobile {
      position: absolute;
      flex-direction: column;
      top: -500px;
      right: 0;
      transition: top ease-in-out .2s;
      padding: 110px 50px 50px;
    }

    a {
      margin-right: 24px;
      font-family: $font-family-finist;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 1.24;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
      position: relative;
      top: 3px;

      &:hover {
        animation: bounce; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s; /* don't forget to set a duration! */

      }

      @media screen and (max-width: 1280px) {
        margin-right: 20px;
        font-size: 18px;
      }
    }
  }

  .header-social-links {
    display: flex;
    flex-grow: 1;
    margin: 0;
    position: relative;
    opacity: 1;
    transition: all linear .2s;
    @include tablet {
      justify-content: flex-end;
      margin-right: 32px;
    }
    @include mobile {
      margin-right: 20px;
    }
    @media screen and (max-width: 710px) {
      display: none;
    }

    .social-link {
      width: 42px;
      height: 42px;
      margin-right: 6px;
      background-color: #151515;
      position: relative;
      z-index: 10;
      @include tablet {
        width: 60px;
        height: 60px;
      }
      @include mobile {
        width: 60px;
        height: 60px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.spotify-icon {
        background: #151515 url("../../images/icon-spotify.svg") center no-repeat;
        background-size: 50% 50%;
      }

      &.yandex-icon {
        background: #151515 url("../../images/icon-yandex-music.svg") center no-repeat;
        background-size: 50% 50%;
      }

      &.bandcamp-icon {
        background: #151515 url("../../images/icon-bandcamp.svg") center no-repeat;
        background-size: 50% 50%;
      }

      &.apple-icon {
        background: #151515 url("../../images/icon-apple.svg") center no-repeat;
        background-size: 50% 50%;
      }

      &.youtube-icon {
        background: #151515 url("../../images/icon-yt.svg") center no-repeat;
        background-size: 50% 50%;
      }

      &.vk-icon {
        background: #151515 url("../../images/icon-vk.svg") center no-repeat;
        background-size: 50% 50%;
      }
    }
  }

  .mobile-menu {
    margin: 0;
    padding: 0;
    display: none;
    height: 9px;
    width: 80px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 99;
    right: 0;
    transition: all ease-in .2s;

    &:before {
      display: block;
      content: '';
      height: 9px;
      width: 80px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      top: -24px;
      right: 0;
      transition: all ease-in .2s;
    }

    &:after {
      display: block;
      content: '';
      height: 9px;
      width: 80px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      top: 15px;
      right: 0;
      transition: all ease-in .2s;
    }

    &.opened {
      width: 60px;

      &:before {
        transition: all ease-in .2s;
        width: 40px;
      }

    }

    @include tablet {
      display: inline-block;
    }
    @include mobile-small {
      width: 40px;
      height: 5px;
      &:before {
        width: 40px;
        height: 5px;
        top: -15px;
      }
      &:after {
        width: 40px;
        height: 5px;
        top: 10px;
      }
      &.opened {
        width: 30px;

        &:before {
          transition: all ease-in .2s;
          width: 20px;
        }

      }
    }
  }

  &.mobile-shown {
    @include tablet {

      .logo {
        transform: translateX(-500px);
        transition: all linear .2s;
      }
      .lang {
        display: none;
      }
      .header-social-links {
        opacity: 0;
        transition: all linear .2s;
      }
      .nav {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        right: 0;
        z-index: 50;
        transition: right ease-in-out .2s;
        background-color: transparent;

        &-item {
          margin-right: 25px;

          a {
            font-size: 2vw;
          }
        }
      }
    }
    @media (max-width: 827px) {
      .nav {
        &-item {
          margin-right: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

      }
    }
    @include mobile {
      .logo {
        z-index: 60;
        transform: none;
      }
      .header-social-links {
        z-index: 55;
      }
      .nav {
        width: 110vw;
        padding-top: 200px;
        background: #000;

        top: 0;
        left: -40px;
        transition: top ease-in-out .2s;

        &-item {
          margin-right: 0;
          margin-bottom: 25px;

          a {
            margin: 0;
            font-size: 40px;
          }
        }
      }
    }

  }
}
