.section.music {
  margin: 0 auto;
  padding: 151px 0 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1168px;
  @include tablet {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 370px;
  }
  @include mobile {
    padding-bottom: 200px;
  }
  @include mobile-small {
    padding-bottom: 100px;
  }

  .music-container {
    position: relative;
    width: 568px;
    height: 609px;
    @include tablet {
      margin: 190px 0 0;
      width: 100%;
      height: 80vw;
    }
    @include mobile {
      width: 80vw;
      height: 85vw;
      margin-top: 100px;
    }
    @include mobile-small {
      margin-top: 50px;
    }

    .music-backborder {
      width: 100%;
      height: 100%;
      border: 20px solid #151515;
      position: absolute;
      top: 0;
      left: 0;
      @include tablet {
        top: 12vw;
        left: -11vw;
      }
    }
    .music-tracks-container {
      width: 568px;
      height: 609px;
      background-color: #0B0B0B;
      position: relative;
      top: 40px;
      left: 40px;
      padding: 50px;
      display: flex;
      flex-direction: column;
      @include tablet {
        width: calc(100vw - 80px);
        height: 100%;
        top: 0;
        left: 0;
        padding: 75px;
      }
      @include mobile {
        width: calc(100vw - 32px);
        left: 0;
        padding: 20px 50px;
      }
      @include mobile-small {
        padding: 14px 20px;
      }
      .logo {
          background: url("../../../images/logo.svg") center no-repeat;
          width: 168px;
          height: 134px;
          //min-width: 140px;
          //min-height: 120px;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .tracks {
          margin: 44px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
          @include tablet {
            justify-content: center;

          }
          @include mobile {
            margin-top: 20px;
          }
          @media screen and (max-width: 600px) {
            margin-top: 0;
          }

          &-track {
              width: 100%;
              padding: 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin: 0 0 20px;
            @include tablet {
              margin-bottom: 40px;
            }
            @include mobile {
              margin-bottom: 20px;
            }

              &:last-of-type {
                margin-bottom: 0;
              }
                .track-band-name {
                  font-family: $font-family-morpheus;
                  font-size: 14px;
                  padding: 0;
                  margin: 0;
                  color: $red;
                  @include mobile-small {
                    font-size: 12px;
                  }
                }
                .track-name {
                  padding: 0;
                  margin: 0;
                  font-family: $font-family-morpheus;
                  text-transform: uppercase;
                  font-size: 18px;
                  @include mobile-small {
                    font-size: 14px;
                  }
                }
                .track-player {
                  margin: 10px 0 0;
                  width: 80%;
                  padding: 0;
                  background-color: transparent;
                  position: relative;
                  @include mobile {
                    width: 100%;
                  }
                  .rhap_controls-section {
                      flex-grow: 0;
                        .rhap_main-controls {
                          .rhap_play-pause-button {
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            background-color: $red;
                            @include mobile-small {
                              height: 30px;
                              width: 30px;
                            }
                            &:hover {
                              box-shadow: none !important;
                            }
                             svg {
                               width: 35%;
                               height: 35%;
                             }
                          }
                        }
                        .rhap_additional-controls {
                          display: none;
                        }
                        .rhap_volume-controls {
                          width: 90px;
                          position: absolute;
                          right: -100px;
                          @include mobile {
                            display: none;
                          }
                            .rhap_volume-container {
                                .rhap_volume-button {
                                  min-width: 31px;
                                  width: 31px;
                                  min-height: 31px;
                                  height: 31px;
                                  background-color: $red;
                                  border-radius: 50%;
                                  &:hover {
                                    box-shadow: none !important;
                                  }
                                  svg {
                                      width: 50%;
                                      height: 50%;
                                      color: #fff;
                                    }
                                }
                                .rhap_volume-bar-area {
                                  .rhap_volume-bar {
                                    background-color: #fff;
                                  }
                                  .rhap_volume-indicator {
                                    background-color: $red;
                                  }
                                }
                            }
                        }
                    }
                    .rhap_progress-section {
                        .rhap_time {
                          font-family: $font-family-oswald;
                          color: #fff;
                        }
                        .rhap_progress-container {
                          .rhap_progress-bar {
                            .rhap_progress-indicator {
                              display: none;
                            }
                            .rhap_progress-filled {
                              background-color: $red;
                            }
                          }
                        }
                    }
                }
            }
        }
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include tablet {
      width: 100%;
    }

    .section-header {
      position: relative;
      z-index: 15;
    }

    .info-text {
      margin-top: 45px;
      margin-left: 100px;
      text-align: left;
      font-size: 17px;
      line-height: 1.58;
      opacity: .5;
      font-family: $font-family-morpheus;
       p, a, b, strong, span {
         font-family: $font-family-morpheus;
       }
      @include tablet {
        font-size: 36px;
        line-height: 1;
        margin-top: 80px;
        margin-left: 0;
        width: 100%;
      }
      @include mobile {
        font-size: 30px;
        margin-top: 32px;
      }
      @include mobile-small {
        font-size: 24px;
      }
    }

    .music-btn {
      padding: 25px 0;
      margin-left: 100px;
      margin-top: 55px;
      @include tablet {
        margin: 100px 0 0;
        padding: 5.2vw 0;
        width: 50vw;
        font-size: 3vw !important;
      }
      @include mobile {
        margin-top: 50px;
      }
      @include mobile-small {
        margin-top: 32px;
      }
    }
  }
}
