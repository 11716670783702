$font-family-metal: 'Metal Mania', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-oswald: 'Oswald', sans-serif;
$font-family-goth: 'CyrillicGoth', sans-serif;
$font-family-gotham: 'Gotham', sans-serif;
$font-family-finist: 'FinistC', sans-serif;
$font-family-iron-lady: 'IronLady', sans-serif;
$font-family-morpheus: 'Morpheus', sans-serif;

// colors
$red: #c7000e;
$red-dark: #7c2525;
$gray-text: #b6b6b6;
$white: #fff
