$mobile-width: 320px;
$tablet-width: 810px;
$desktop-width: 1024px;
$large-desktop-width: 1280px;

@mixin mobile-micro {
  @media (max-width: 350px) {
    @content;
  }
}

@mixin mobile-small {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width - 1px}){
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}
