.input-form {
  position: relative;
  input {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 63px;
    @include mobile-small {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
  button {
    height: 58px;
    position: absolute;
    top: 4px;
    right: 8px;
    z-index: 10;
    @include mobile-small {
      font-size: 14px;
    }
  }
}
