.section.news {
  margin: 0 auto;
  padding: 148px 0 120px;
  width: calc(100% - 160px);
  max-width: 1168px;
  min-height: 1023px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

    .torch-right {
      display: none;
      @include desktop {
        display: block;
        width: 250px;
        height: 600px;
        background: url("../../../images/torch-right.webp") center no-repeat;
        background-size: contain;
        position: absolute;
        z-index: -1;
        right: -100px;
        top: 0;
      }
      @include tablet {
        top: -100px;
      }
      @include mobile-small {
        width: 200px;
        height: 550px;
      }
    }
    .torch-left {
      display: none;
      @include desktop {
        display: block;
        width: 250px;
        height: 600px;
        background: url("../../../images/torch-left.webp") center no-repeat;
        background-size: contain;
        position: absolute;
        z-index: -1;
        left: -100px;
        top: 0;
      }
      @include tablet {
        top: -100px;
      }
      @include mobile-small {
        width: 200px;
        height: 550px;
      }
    }
    .bg-hole {
      display: none;
      @include tablet {
        display: block;
        width: 1920px;
        height: 1200px;
        background:  url("../../../images/hole.webp") top no-repeat;
        box-sizing: content-box;
        background-size: contain;
        position: absolute;
        z-index: -2;
        top: 0;
        left: calc(50% - 1070px);
      }
    }

  @include tablet {
    padding: 150px 0 150px;
  }
  @include mobile {
    padding: 100px 0 100px;
    width: calc(100% - 64px);
  }

    .section-header {
      width: fit-content;
      margin: 0 auto;

      .small-header {
        margin: 0 auto;
        text-align: center;
      }
    }

    .news-container {
      width: 100%;
      margin: 55px auto 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  .news-carousel {
    margin: 20px auto 0;
    width: 100vw;
    position: relative;
    left: -80px;
    padding-top: 50px;
    background-color: transparent;
    @include mobile {
      padding-top: 50px;
      left: -32px;
    }
  }
  .news-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 30px;
    min-height: 440px;
    &:hover {
      cursor: pointer;
      animation: pulse;
      animation-duration: 1s; /* don't forget to set a duration! */
    }

    width: calc((100% - 60px) / 3);
    &:nth-of-type(3n),
    &:last-of-type {
      margin-right: 0;
    }
    @include tablet {
      margin: 0 auto;
      width: 100%;
      padding: 0 2vw;
      min-height: unset;
    }
    @include mobile {
      padding: 0 10vw;

    }

    .image-container {
      width: 100%;
      height: 216px;
      background-color: #000;
      @include  tablet {
        background-color: #151515;
        width: 100%;
        height: 30vw;
      }
      @include mobile {
        height: 40vw;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .article-title {
      margin-top: 32px;
      width: 100%;
      text-align: center;
      font-family: $font-family-finist;
      font-weight: 400;
      font-size: 30px;
      line-height: 1;
      letter-spacing: 3px;
      color: #fff;
      text-transform: uppercase;
      @include tablet {
        margin: 70px 0 0;
        font-size: 50px;
      }
      @include mobile-small {
        margin-top: 25px;
        font-size: 32px;
      }
    }

    .article-date {
      font-size: 22px;
      line-height: 1.15;
      margin: 16px auto 0;
      text-align: center;
      font-family: $font-family-finist;
      letter-spacing: 3px;
      color: $red;
      text-transform: uppercase;
      @include tablet {
        font-size: 34px;
        margin: 32px 0 0;
      }
      @include mobile-small {
        margin-top: 25px;
        font-size: 26px;
      }
    }

    .article-text {
      margin: 22px auto 0;
      width: 100%;
      text-align: center;
      color: #fff;
      opacity: .5;
      font-family: $font-family-morpheus;
      font-size: 17px;
      line-height: 1.5;
      @include tablet {
        font-size: 32px;
        margin: 32px 0 0;
      }
      @include mobile-small {
        margin-top: 25px;
        font-size: 22px;
      }
    }
  }




    .news-btn {
      margin: 28px auto 0;
      width: fit-content;
      padding: 26px 54px;
      @include tablet {
        display: none;
      }
    }
}
