.page-music {
  position: relative;
  z-index: 10;
  @include mobile {
    padding-bottom: 30px;
  }

    .playlist-container {
      width: 100%;
      margin: 61px auto 0;
      position: relative;
      @include tablet {
        margin-top: 68px;
      }
      @include mobile {
        margin-top: 48px;
      }


      .player-wrapper {
        width: 100%;
        max-width: 1190px;
        margin: 40px auto;
        @media screen and (max-width: 1290px) {
          width: calc(100% - 100px);
        }
        @include tablet {
          margin-top: 20px;
        }
        @include mobile {
          width: calc(100% - 40px);
        }
      }

        .top-block-container {
          margin: 0 auto;
          padding: 53px 0;
          background: rgba(27, 27, 27, 0.5);
          position: relative;
          @include tablet {
            padding-top: 27px;
            padding-bottom: 35px;
          }
          @include mobile {
            padding: 15px 0;
          }

          .buy-link {
            position: absolute;
            bottom: 10px;
            right: 10px;
            .btn-buy {
              padding: 12px 20px;
            }
            @include mobile {
              position: relative;
              bottom: 0;
              right: 0;
              margin: 24px 0 0 auto;
              .btn-buy {
                margin-top: 24px;
              }
            }
          }

          .top-block {
              margin: 0 auto;
              width: 100%;
              max-width: 1190px;
              display: flex;
              @media screen and (max-width: 1290px) {
                width: calc(100% - 100px);
              }
              @include mobile {
                width: calc(100% - 40px);
              }
            @include mobile-small {
              flex-direction: column;
            }

                .cover {
                  width: 300px;
                  height: 289px;
                  margin-right: 50px;
                  position: relative;
                  @include tablet {
                    margin-right: 30px;
                  }
                  @include mobile {
                    width: 200px;
                    height: 200px;
                    min-width: 200px;
                    min-height: 200px;
                    margin-right: 15px;
                  }
                  @include mobile-small {
                    width: 100%;
                    margin-bottom: 12px;
                    &:before {
                      display: block;
                      content: '';
                      width: 100%;
                      padding-top: 100%;
                    }
                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                }
                .info {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                    .subtitle {
                      margin: 0;
                      font-family: $font-family-morpheus;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 25px;
                      line-height: 1.2;
                      display: flex;
                      align-items: center;
                      color: #B6B6B6;
                      @include tablet {
                        font-size: 12px;
                      }
                    }
                    .title {
                      margin-top: 20px;
                      font-family: $font-family-finist;
                      font-style: normal;
                      font-weight: normal;
                      letter-spacing: 3px;
                      font-size: 70px;
                      line-height: 1.2;
                      display: flex;
                      align-items: center;
                      color: #FFFFFF;
                      @include tablet {
                        font-size: 50px;
                        margin-top: 15px;
                      }
                      @include mobile {
                        margin-top: 10px;
                      }
                      @include mobile-small {
                        font-size: 32px;
                      }
                    }
                    .description {
                      margin: 0;
                      max-width: 546px;
                      font-family: $font-family-morpheus;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 1.2;
                      color: #B6B6B6;
                      text-align: left;
                      @include tablet {
                        font-size: 18px;
                        margin-top: 0;
                      }
                      @include mobile {
                        margin-top: 0;
                        font-size: 12px;
                      }
                    }
                }
            }
        }
        .playlist {
          margin: 40px auto 0;
          width: 100%;
          max-width: 1190px;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 1290px) {
            margin-top: 32px;
            width: calc(100% - 100px);
          }
          @include mobile {
            margin-top: 20px;
            width: calc(100% - 40px);
          }
            .track-header {
              width: 100%;
              padding-left: 100px;
              padding-right: 15px;
              margin: 0;
              text-align: left;
              display: flex;
              justify-content: space-between;
              font-family: $font-family-morpheus;
              letter-spacing: .5px;
              @include mobile-small {
                display: none;
              }
              &:after {
                content: "";
                width: 20px;
                height: 20px;
                display: block;
                background: url("../../../images/clock.svg") center no-repeat;
                background-size: contain;
              }
            }
            .tracks {
              width: 100%;
              padding: 0 15px 0 0;
              margin: 20px 0 0;
              display: flex;
              flex-direction: column;
              max-height: 525px;
              overflow-y: scroll;
              &::-webkit-scrollbar {
                width: 3px;
              }
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              }

              &::-webkit-scrollbar-thumb {
                background-color: $red;
                outline: 1px solid $red;
              }

              @include mobile-small {
                height: 120px;
              }
                .track {
                  cursor: pointer;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  margin-bottom: 24px;
                  &.active {
                    background-color: rgb(10, 10, 10);
                      .name {
                        font-weight: bold;
                      }
                  }
                  @include mobile-small {
                    margin-bottom: 15px;
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                    .cover {
                      width: 70px;
                      height: 67px;
                      margin-right: 30px;
                      @include mobile {
                        width: 52px;
                        height: 49px;
                        margin-right: 18px;
                      }
                      @include mobile-small {
                        display: none;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                      }
                    }
                    .number {
                      margin: 0 26px 0 0;
                      padding: 0;
                      font-family: $font-family-morpheus;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 15px;
                      line-height: 1.2;
                      color: #FFFFFF;
                      @include mobile-small {
                        font-size: 10px;
                        margin-right: 9px;
                      }
                    }
                    .name {
                      margin: 0 26px 0 0;
                      padding: 0;
                      font-family: $font-family-morpheus;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 1.2;
                      color: #FFFFFF;
                      @include mobile {
                        font-size: 18px;
                      }
                      @include mobile-small {
                        font-size: 15px;
                        margin-right: 10px;
                      }
                    }
                    .length {
                      flex-grow: 1;
                      margin: 0;
                      padding: 0;
                      font-family: $font-family-morpheus;
                      font-style: normal;
                      text-align: right;
                      font-weight: normal;
                      font-size: 15px;
                      line-height: 1.2;
                      color: #FFFFFF;
                      @include mobile-small {
                        font-size: 10px;
                      }
                    }
                }
            }
        }
    }

    .albums-container {
      width: 100%;
      max-width: 1190px;
      margin: 150px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: 1290px) {
        width: calc(100% - 100px);
      }
      @include mobile {
        margin-top: 50px;
        width: calc(100% - 40px);
      }

        .album {
          cursor: pointer;
          width: 48%;
          margin-bottom: 50px;
          @include mobile {
            margin-bottom: 30px;
          }

            .cover {
              width: 100%;
              position: relative;
              overflow: hidden;
              &:before {
                content: "";
                display: block;
                width: 100%;
                padding-top: 100%;
              }
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
            }
            .info {
              width: 100%;
              margin-top: 20px;
              @include mobile {
                margin-top: 15px;
              }
                .subtitle {
                  margin: 0;
                  padding: 0;
                  font-family: $font-family-morpheus;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 1.2;
                  text-align: left;
                  color: #B6B6B6;
                  @include mobile {
                    font-size: 12px;
                  }
                }
                .title {
                  margin: 15px 0 0;
                  font-family: $font-family-finist;
                  font-style: normal;
                  font-weight: normal;
                  letter-spacing: 3px;
                  font-size: 50px;
                  line-height: 1.2;
                  text-align: left;
                  color: #FFFFFF;
                  @include mobile {
                    font-size: 24px;
                    margin-top: 10px;
                  }
                }
                .description {
                  margin-top: 14px;
                  font-family: $font-family-morpheus;
                  font-size: 16px !important;
                  line-height: 1.2;
                  color: #fff !important;
                  text-align: left;

                  p {
                    margin: 15px 0 0 !important;
                    padding: 0;
                    font-family: $font-family-morpheus;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px !important;
                    line-height: 1.2;
                    text-align: left;
                    color: #fff !important;
                    @include mobile {
                      font-size: 12px !important;
                      margin-top: 10px;
                    }

                  }
                }
            }
        }
    }
  .player-wrapper {
    margin: 40px auto 0;
    padding-right: 10px;
    width: 100%;
    max-width: 1170px;
    @media screen and (max-width: 1270px) {
      width: calc(100% - 100px);
    }
    @include tablet {
      margin-top: 40px;
    }
    @include mobile {
      margin-top: 20px;
      width: calc(100% - 40px);
    }
  }

}
