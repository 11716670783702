.pagination {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mobile {
    width: 100%;
  }

    .pages-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      padding: 50px 0 ;
      @include mobile {
        width: 100%;
      }
      .page-element {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        &.active {
          width: 15px;
          height: 15px;
          background-color: $red;
        }
      }

    }
    .buttons-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
        .button {
          width: 45%;
          padding: 25px 100px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          &:disabled {
            background-color: #252525;
            box-shadow: none;
          }
          @include mobile {
            padding: 25px 0;
          }
        }
    }
}
