h1 {
  font-family: $font-family-metal;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 1.6;
  color: #fff;
}

p {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: #e5e6ed;
}

.section-title {
  width: fit-content;
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 1.2;
  color: #B6B6B6;
  position: relative;
  z-index: 10;
  &:before {
    display: block;
    content: '';
    width: 83%;
    height: 32px;
    position: absolute;
    z-index: -1;
    top: 22px;
    left: -15px;
    background-color: #7c2525;
  }
  @include tablet {
    font-size: 25px;
    &:before {
      height: 20px;
      top: 13px;
      left: -9px;
    }
  }
  @include mobile {
    font-size: 20px;
    &:before {
      height: 15px;
      top: 10px;
      left: -5px;
    }
  }
}

.section-all {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;
  text-decoration-line: underline;
  color: #B6B6B6;
}

.section-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #e5e6ed;
}

a {
  transition: all linear .2s;
  &:hover {
    transition: all linear .2s;
    color: $red !important;
  }
}
