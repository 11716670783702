@import "../variables";

button {
  width: 215px;
  padding: 30px 0;
  font-family: $font-family-morpheus !important;
  font-style: normal;
  text-transform: uppercase !important;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 10;
  background-color: $red;
  border: none;
  transition: all ease-in-out .2s;
  &:focus {
      box-shadow: none;
    }

    &:hover {
      transition: all ease-in-out .2s;
      -webkit-box-shadow: 5px 5px 21px 5px rgba(199,0,14,0.77);
      box-shadow: 5px 5px 21px 5px rgba(199,0,14,0.77);
      @include tablet {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
}
