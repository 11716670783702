.main-info {
  margin: 0 auto;
  max-width: 1168px;
  padding: 0 0 284px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include desktop {
    padding-bottom: 120px;
    background: url("../../../images/tablet-snake-skin-top.webp") center no-repeat;
  }
  @include tablet {
    width: 100%;
    padding-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  @include mobile {
    margin-top: 0;
    padding-top: 43px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 140px;

      @include tablet {
        margin: 60px auto 0;
        width: calc(100% - 160px);
      }
      @include mobile {
        width: calc(100% - 64px);
      }
      @include mobile-small {
        margin-top: 50px;
      }

      .text {
        font-family: $font-family-oswald;
        font-size: 20px;
        line-height: 1.15;
        font-style: normal;
        color: #fff;
        @include tablet {
          font-size: 4.8vw;
        }

        span {
          color: $red;
        }
      }

      &-subtitle {
        font-family: $font-family-finist;
        font-style: normal;
        font-weight: normal;
        font-size: 100px;
        line-height: 1.25;
        color: #FFFFFF;
        text-align: left;
        position: relative;
        z-index: 10;

        margin: 0;

          @include tablet {
            font-size: 15vw;
            margin-top: 20px;
          }
          span {
            color: $red;
          }
      }

      .buttons-block {
        display: flex;
        margin-top: 68px;

        @include tablet {
          margin-top: 5vw;
          flex-direction: column;
          width: 50vw;
          button {
            padding: 5.2vw 0;
            width: 100%;
            font-size: 3vw !important;
          }
        }

          .albums-btn {
            margin-right: 16px;
            background-color: #151515;
            @include tablet {
              margin-right: 0;
              margin-bottom: 5.2vw;
            }
          }
      }
  }
  .image {
    background: url("../../../images/logo-drrakon.webp") center no-repeat;
    background-size: contain;
    width: 740px;
    height: 771px;
    position: absolute;
    top: 120px;
    left: 50%;
    z-index: 9;
    @media screen and (max-width: 1500px) {
      left: 45%;
    }
    @include tablet {
      background: url("../../../images/logo-drrakon.webp") center no-repeat;
      background-size: contain;
      display: block;
      width: 100vw;
      height: 103vw;
      margin: 0;
      position: relative;
      top: 0;
      left: 0;
      z-index: 10;
    }
    @include mobile-small {
      margin-top: 50px;
    }
  }
}
